import React from 'react';
import { Link } from 'gatsby';

const OurSolutions = () => {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Why Choose Us?</h2>
          <p>
            Some of our many qualities for why you should choose us in making
            your next project a break-through
          </p>
        </div>

        <div className="row justify-center">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>On-Time Delivery Assurance</h3>
              <p>
                Count on us for punctual project completion, ensuring your
                deadlines are met without compromise
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h3>Cost-Effective Solutions</h3>

              <p>
                Optimize your budget with our efficient processes and
                competitive pricing, delivering value without sacrificing
                quality
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>Innovative Expertise</h3>

              <p>
                Harness our team's advanced skills and knowledge to drive
                innovation and create bespoke solutions tailored to your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutions;
