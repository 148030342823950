import React from 'react';
import ReactWOW from 'react-wow';
import partner1 from '../../assets/images/partner/dcc.png';
import partner2 from '../../assets/images/partner/tamm.png';
import partner3 from '../../assets/images/partner/sbm.png';
import partner4 from '../../assets/images/partner/kyros.png';
import partner5 from '../../assets/images/partner/vistajet.png';
import partner6 from '../../assets/images/partner/mercy.png';

const Partner = () => {
  return (
    <div className="partner-area pt-50 pb-100 bg-f1f8fb">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2 col-6 col-sm-4 col-md-4">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="single-partner-item">
                <img src={partner1} alt="partner" />
              </div>
            </ReactWOW>
          </div>

          <div className="col-lg-2 col-6 col-sm-4 col-md-4">
            <ReactWOW delay=".2s" animation="fadeInUp">
              <div className="single-partner-item">
                <img src={partner2} alt="partner" />
              </div>
            </ReactWOW>
          </div>

          <div className="col-lg-2 col-6 col-sm-4 col-md-4">
            <ReactWOW delay=".3s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner3} alt="partner" />
                </div>
              </div>
            </ReactWOW>
          </div>

          <div className="col-lg-2 col-6 col-sm-4 col-md-4">
            <ReactWOW delay=".4s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner4} alt="partner" />
                </div>
              </div>
            </ReactWOW>
          </div>

          <div className="col-lg-2 col-6 col-sm-4 col-md-4">
            <ReactWOW delay=".5s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner5} alt="partner" />
                </div>
              </div>
            </ReactWOW>
          </div>

          <div className="col-lg-2 col-6 col-sm-4 col-md-4">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner6} alt="partner" />
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>

      <div className="divider"></div>
    </div>
  );
};

export default Partner;
